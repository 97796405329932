<template>
  <div id="app">
    <div class="section content-title-group">
      <h2 class="title">{{ titulo }}</h2>
    </div>
    <!-- eslint-disable-next-line-->
    </br></br>
    <span class="texto30px">
      Crea tu página aquí.
      <!--<span class="flecha-doble-invertida texto30px resaltador-celeste"></span
    >--></span>
    <!--<FilterBar :mensajeFiltro="mensajeFiltro" />-->
    <div class="containerCreacionPagina" >
       <label  class="labelCreacionPagina">
      Selecionar espacio
    </label>
      <div style="float:left;">
        <select v-model="espacioeleccionado" name="selecionespacio" >
          <option v-for="elemento in espaciosposibles" v-bind:key="elemento.id" v-bind:value="elemento.id">
            {{elemento.titulo }}</option> 
       </select>
     </div>
    </div>
    <div class="containerCreacionPagina" >
        <label class="labelCreacionPagina">Nombre para URL</label>
            <input
              v-model="nombreurl"
              class="inputCreacionPagina"
              id="nombreurl"
              required
              placeholder="Nombre para url"
            />
            <label class="labelCreacionPagina">Nombre para mostrar</label>
            <input
              v-model="nombreparamostrar"
              class="inputCreacionPagina"
              id="nombreparamostrar"
              required
              placeholder="Nombre para mostrar"
            />
            <label class="labelCreacionPagina">Parent id</label>
            <input
              v-model="parentid"
              class="inputCreacionPagina"
              id="parentid"
              placeholder="xxxxxxx-yyyyyyyy"
            />
            <label class="labelCreacionPagina">Habilitado
            <input
              v-model="estahabilitado"
              class="inputCreacionPagina"
              type="checkbox"
              id="estahabilitado"
              checked
              placeholder="Habilitado"
            />
            </label>
            
            <label  v-if="!estahabilitado"  class="labelCreacionPagina">
                Fecha de habilitación
            <input
                v-model="fecha_habilitacion"
                class="inputCreacionPagina"
                id="fechahabilitacion"
                type= "date"
                placeholder="Fecha de habilitación"
                />
            </label>

            <label class="labelCreacionPagina">Incluir página
            <input
              v-model="tienepagina"
              class="inputCreacionPagina"
              type="checkbox"
              id="tienepagina"
            />
            </label>

            <label v-if="tienepagina"  class="labelCreacionPagina">
                Tipo de página
                <select v-model="tipopagina" name="tipopagina">
                    <option value="texto-normal">Texto</option>
                    <option value="texto-agrupado-en-secciones">Texto agrupado en secciones</option>
                </select>
            <!-- eslint-disable-next-line-->
            </br>
            Componentes
            <input
              v-model="componente_pagina"
              class="inputCreacionPagina"
              id="componente_pagina"
              placeholder="componente_pagina"
            />
             
            </label>
            <div v-if="tienepagina">
              
            <div 
              v-for="seccion in secciones"
              v-bind:key="seccion.section_number"
              class="panel panel-default"
            >
            <!-- eslint-disable-next-line-->
            </br>
             
            <p>Seccion {{seccion.id}}</p>
            <input
              v-model="seccion.titulo"
              class="inputCreacionPagina"
              id=""
              placeholder="Título"
            />
            <Editor :id="'editor-'+seccion.id" :init="cfg_tiny"/>
 
            </div>
            <button type="button" @click="agregarSeccion">Agregar seccion</button> 
            </div>
              <p v-if="error" class="error" :style="colorMensaje">{{mensajeResultado}}</p>
            <input type="submit" class="inputCreacionPagina" @click="crearPagina" value="Crear página" />
          </div>
          
  </div>
</template>

<script>

import {_crearPagina} from './scripts/paginas';
import {mapGetters} from 'vuex';
import Editor from './tiny/tiny-editor';
import Vue from 'vue';
import VueEvents from 'vue-events';
import {_tiny_config_default} from './tiny/config-concepto';
Vue.use(VueEvents);
Vue.component('tiny-editor',Editor);

export default {
  name: 'app',
  components: {
    //FilterBar,
    //MapModal,
    Editor,
  },
  computed: {
    ...mapGetters(['nav']),
  },
  data() {
    return {
      cfg_tiny: _tiny_config_default,
      titulo: 'Crear nueva página',
      estahabilitado: true,
      nombreurl: '',
      nombreparamostrar: '',
      esprivado: true,
      error: false,
      tienepagina: false,
      tipopagina: '',
      pagina: '',
      fecha_habilitacion:'',
      elementos:{},
      parentid:'',
      componente_pagina:'',
      secciones: [],
      cantidadsecciones:0,
      espaciosposibles:[],
      mensajeResultado:'',
      colorMensaje: 'color:red;',
      espacioseleccionado:'',
    };
  },
  created() {
    for(let i=0; i< this.nav.length; i++){
      console.log(this.nav);
      this.espaciosposibles.push({id:this.nav[i].id_espacio,titulo: this.nav[i].navegacion.titulo});
    }
  },
  methods: {
      agregarSeccion: function(){
        this.cantidadsecciones++;
        console.log('agregandoSeccion');
        let cantidadsecciones = '' + this.cantidadsecciones;
        this.secciones.push({id:cantidadsecciones, titulo:'',contenido:''});
        console.log(this.secciones);
      },
      crearPagina: function(){
          //console.log(this.pagina);
          //let pagina = this.pagina;
          //pagina = pagina.replaceAll('"','\\"');
          for(let isecs = 0; isecs <  this.secciones.length; isecs++){
            let idEditor = 'editor-' + this.secciones[isecs].id;
            let editor = window.tinymce.editors[idEditor];
            //Este arreglo hay que hacerlo siempre.
            let div = document.createElement('div');
            div.innerHTML = editor.getContent({format: 'raw'});
            let elements = div.childNodes;
            for(let i = 0; i< elements.length;i++) {
              try{
              if(elements[i].getAttribute('aria-controls').toString().indexOf('colapsarTexto')>-1){
                elements[i].setAttribute('href', '#'+elements[i].getAttribute('aria-controls'));
                
              }
              // eslint-disable-next-line no-empty
              }catch{}
            }
            this.secciones[isecs].contenido =  div.innerHTML;
          }
          
          let pagina = this.secciones;
          //console.log(pagina);
          let privado = ''+this.esprivado;
          //pagina = this.pagina==='' ?  'NULL' :JSON.parse(pagina);
          let fechahabilitacion = this.fecha_habilitacion === '' ? 'NULL':this.fecha_habilitacion;
          this.parentid = this.parentid==='' ? 'NULL':this.parentid;
          this.componente_pagina = this.componente_pagina ===''?'NULL':this.componente_pagina;
          console.log(pagina);
          let datos = {
              habilitado: this.estahabilitado,
              fecha_habilitacion: fechahabilitacion,
              lastcount: 0,
              privado: privado,
              pagina: pagina,
              path: this.nombreurl,
              titulo: this.nombreparamostrar,
              creado_por:1,
              id_espacio: this.espacioeleccionado,
              elementos: this.elementos,
              parent_id:this.parentid,
              componente_pagina: this.componente_pagina,
          };
          _crearPagina(datos) .then(resultado => {
          console.log(resultado);
          if (resultado.status === 200) {  
            //console.log('Resultado: ' + JSON.stringify(resultado));
            this.error = true;
            this.colorMensaje = 'color:green;';
            this.mensajeResultado = resultado.msg;
          } else {
            this.error = true;
            //console.log('ERROR: -> ' + JSON.stringify(resultado));
            this.colorMensaje = 'color:red;';
            this.mensajeResultado = resultado.msg;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          this.error = true;
          this.colorMensaje = 'color:red;';
          this.mensajeResultado = 'Ocurrió un problema';
          //console.log('ERROR: -> ' + JSON.stringify(resultado));
        });
      },
    cargarModalElemento: function(id, nombreModal) {
      id = id.replace('#', '');
      this.informacion.filter(function(elem) {
        if (elem.element_data_id == id) {
          document.getElementById('textoModal').innerHTML =
            elem.element_data_content;
          document.getElementById('exampleModalLabel').innerText = nombreModal;
          // eslint-disable-next-line no-undef
          $('#exampleModal').modal({backdrop: false});
        }
      });
    },
  },
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.labelCreacionPagina{
     float: left;
     width:100%;
     text-align:left;
}
.inputCreacionPagina, .selectCreacionPagina {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.inputCreacionPagina[type=submit] {
  width: 100%;
  background-color: #04AA6D;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.inputCreacionPagina[type=checkbox] {
  width: 10px;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
}

.inputCreacionPagina[type=submit]:hover {
  background-color: #45a049;
}

.containerCreacionPagina {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}
</style>